<script setup>
import JetFormSection from '@/Components/Section/CardSection.vue';
import JetActionMessage from '@/Jetstream/ActionMessage.vue';
import JetButton from '@/Jetstream/Button.vue';
import JetLabel from '@/Jetstream/Label.vue';
import { objectMap } from '@/utilities.js';
import { useForm } from '@inertiajs/vue3';
import { inject } from 'vue';
const route = inject('route');
const props = defineProps({
    team: Object,
    permissions: Object,
});

const form = useForm({
    name: props.team.name,
});

const updateTeamName = () => {
    form.put(route('teams.update', props.team), {
        errorBag: 'updateTeamName',
        preserveScroll: true,
        onError: (errors) => (form.errors = objectMap(errors, (error) => [error])),
    });
};
</script>

<template>
    <JetFormSection @submitted="updateTeamName">
        <template #title> Team Name </template>

        <template #description> The team's name and owner information. </template>

        <template #form>
            <!-- Team Owner Information -->

            <div class="col-span-6">
                <JetLabel value="Team Owner" />

                <div class="flex items-center mt-2">
                    <img
                        class="object-cover w-12 h-12 rounded-full"
                        :src="team.owner.profile_photo_url"
                        :alt="team.owner.name"
                    />

                    <div class="ml-4 leading-tight">
                        <div>{{ team.owner.name }}</div>
                        <div class="text-sm text-gray-500-700">
                            {{ team.owner.email }}
                        </div>
                    </div>
                </div>
            </div>

            <FormKit
                type="text"
                name="name"
                label="Location name"
                v-model="form.name"
                validation="required|string|min:3|max:255"
                :errors="form.errors.name"
            />
        </template>

        <template v-if="permissions.canUpdateTeam" #actions>
            <JetActionMessage :on="form.recentlySuccessful" class="mr-3"> Saved. </JetActionMessage>

            <JetButton :class="{ 'opacity-25': form.processing }" :disabled="form.processing"> Save </JetButton>
        </template>
    </JetFormSection>
</template>
